import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from 'aws-amplify/auth';

const About = ({ signOut }) => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedInfo, setEditedInfo] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/about');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const formattedReleaseDate = formatDateForDisplay(data["Release Date"]);

        setProductInfo({
          teamNumber: data["Team Number"],
          versionNumber: data["Version Number"],
          releaseDate: formattedReleaseDate, // Format for display
          productName: data["Product Name"],
          productDescription: data["Product Description"]
        });

        setEditedInfo({
          teamNumber: data["Team Number"], // Keep team number unchanged
          versionNumber: data["Version Number"],
          releaseDate: formattedReleaseDate, // Format for editing
          productName: data["Product Name"],
          productDescription: data["Product Description"]
        });
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchProductData();

    const fetchCurrentUserRole = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const currentRole = attributes['custom:userRole'];
        setUserRole(currentRole);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentUserRole();
  }, []);

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    
    // Adjust for timezone offset to avoid issues with local time
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
    return utcDate.toLocaleDateString('en-US', options);
  };

  const formatDateForDatabase = (dateString) => {
    const [month, day, year] = dateString.split('/');
    const formattedDate = new Date(`20${year}-${month}-${day}`); // Assuming year is in YY format
    return formattedDate.toISOString();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleEditSubmit = async () => {
    try {
      const formattedReleaseDate = formatDateForDatabase(editedInfo.releaseDate); // Convert for database

      const requestBody = {
        ...editedInfo,
        releaseDate: formattedReleaseDate, // Use the formatted date for the request
      };

      const response = await fetch('https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/about', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to update product information');
      }

      // Update productInfo with the editedInfo after successful update
      const updatedReleaseDate = formatDateForDisplay(formattedReleaseDate); // Convert back for display
      setProductInfo({ ...editedInfo, releaseDate: updatedReleaseDate });
      setIsEditing(false); // Exit edit mode after submission
    } catch (error) {
      console.error('Error submitting edited information:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <h1>About Page</h1>
      <section className="product-info">
        <h3>Product Information</h3>
        <ul>
          <li>
            <strong>Team #: </strong>
            <span>{productInfo.teamNumber}</span> {/* Display team number as static text */}
          </li>
          <li>
            <strong>Version #: </strong>
            <span>{isEditing ? <input type="text" name="versionNumber" value={editedInfo.versionNumber} onChange={handleEditChange} /> : productInfo.versionNumber}</span>
          </li>
          <li>
            <strong>Release Date: </strong>
            <span>{isEditing ? <input type="text" name="releaseDate" value={editedInfo.releaseDate} onChange={handleEditChange} placeholder="MM/DD/YY" /> : productInfo.releaseDate}</span>
          </li>
          <li>
            <strong>Product Name: </strong>
            <span>{isEditing ? <input type="text" name="productName" value={editedInfo.productName} onChange={handleEditChange} /> : productInfo.productName}</span>
          </li>
          <li>
            <strong>Product Description: </strong>
            <span>{isEditing ? <input type="text" name="productDescription" value={editedInfo.productDescription} onChange={handleEditChange} /> : productInfo.productDescription}</span>
          </li>
        </ul>
        {userRole === 'Admin' && !isEditing && (
          <button onClick={() => setIsEditing(true)} style={styles.editButton}>
            Edit
          </button>
        )}
        {isEditing && (
          <div>
            <button onClick={handleEditSubmit} style={styles.saveButton}>
              Save
            </button>
            <button onClick={() => setIsEditing(false)} style={styles.cancelButton}>
              Cancel
            </button>
          </div>
        )}
      </section>

      <div style={styles.dropdownContainer}>
        <button onClick={toggleDropdown} style={styles.dropdownButton}>
          Menu
        </button>

        {dropdownOpen && (
          <div style={styles.dropdownContent}>
            <button
              onClick={() => {
                navigate('/dashboard');
                setDropdownOpen(false);
              }}
              style={styles.dropdownItem}
            >
              Dashboard
            </button>

            {(userRole === 'Admin' || userRole === 'Sponsor') && (
              <button
                onClick={() => {
                  navigate('/create-account');
                  setDropdownOpen(false);
                }}
                style={styles.dropdownItem}
              >
                Create Account
              </button>
            )}
            <Link to="/bio" style={styles.dropdownItem} onClick={() => setDropdownOpen(false)}>
              Bio
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  dropdownContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
  },
  dropdownButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  dropdownContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '40px',
    right: '0',
    backgroundColor: '#f1f1f1',
    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
    zIndex: 1,
  },
  dropdownItem: {
    padding: '10px',
    textAlign: 'left',
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
  },
  editButton: {
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  saveButton: {
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: 'green',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  cancelButton: {
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default About;
