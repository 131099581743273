import React, { useEffect, useState } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth'; // Use this to get the user's email
import { Link, useNavigate } from 'react-router-dom';

const Bio = () => {
    const [bioData, setBioData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false); // For controlling dropdown visibility
    const [userRole, setUserRole] = useState(null); // State to hold user role
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBioData = async () => {
            try {
                // Fetch user attributes to get the current user's email
                const attributes = await fetchUserAttributes();
                const email = attributes['email']; // Get the email of the current user
                setUserRole(attributes['custom:userRole']); // Set the user role

                // Call the Lambda function with the email
                const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio?email=${encodeURIComponent(email)}`);
                
                if (!response.ok) {
                    // Handle non-200 response
                    const errorBody = await response.text(); // Get the error body
                    throw new Error(`Failed to fetch bio data: ${errorBody}`);
                }
                
                const data = await response.json(); // Parse the JSON directly
                console.log('Fetched bio data:', data); // Log the fetched data
                setBioData(data); // Set the parsed data
                setFirstName(data.firstName); // Initialize firstName with fetched data
                setLastName(data.lastName);   // Initialize lastName with fetched data
            } catch (error) {
                console.error('Error fetching bio data:', error);
                setError('Failed to load bio data');
            } finally {
                setLoading(false);
            }
        };

        fetchBioData();
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleEditClick = () => {
        setIsEditing(true); // Enter edit mode
    };

    const handleSaveClick = async () => {
        try {
            // Fetch user attributes to get the current user's email
            const attributes = await fetchUserAttributes();
            const email = attributes['email'];

            // Make API call to update first and last name in the database
            const response = await fetch(`https://g77ldxj1o4.execute-api.us-east-1.amazonaws.com/Team21API/bio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    firstName,
                    lastName,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update bio data');
            }

            // Update bioData in the state without refreshing the page
            setBioData((prevData) => ({
                ...prevData,
                firstName,
                lastName,
            }));

            // Exit edit mode
            setIsEditing(false);

        } catch (error) {
            console.error('Error updating bio data:', error);
            setError('Failed to update bio data');
        }
    };

    if (loading) {
        return <p>Loading...</p>; // Show loading state
    }

    if (error) {
        return <p>{error}</p>; // Show error message
    }

    return (
        <div style={{ padding: '20px' }}>
            <h1>Bio Page</h1>

            {/* Check if in edit mode */}
            {isEditing ? (
                <div>
                    <label>
                        First Name:
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </label>
                    <br />
                    <label>
                        Last Name:
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </label>
                    <br />
                    <button onClick={handleSaveClick} style={styles.saveButton}>
                        Save
                    </button>
                </div>
            ) : (
                <div>
                    <h3>First Name: {bioData.firstName}</h3>
                    <h3>Last Name: {bioData.lastName}</h3>
                    <h3>Sponsor Org: {bioData.sponsorOrg}</h3>

                    {/* Edit Button */}
                    <button onClick={handleEditClick} style={styles.editButton}>
                        Edit
                    </button>
                </div>
            )}

            {/* Dropdown Menu */}
            <div style={styles.dropdownContainer}>
                <button onClick={toggleDropdown} style={styles.dropdownButton}>
                    Menu
                </button>

                {dropdownOpen && (
                    <div style={styles.dropdownContent}>
                        {/* Button to navigate to the Dashboard first */}
                        <button
                            onClick={() => {
                                navigate('/dashboard');
                                setDropdownOpen(false); // Close dropdown after navigating
                            }}
                            style={styles.dropdownItem}
                        >
                            Dashboard
                        </button>

                        {/* Show "Create Account" only for Admin and Sponsor */}
                        {userRole === 'Admin' || userRole === 'Sponsor' ? (
                            <button
                                onClick={() => {
                                    navigate('/create-account');
                                    setDropdownOpen(false); // Close dropdown after navigating
                                }}
                                style={styles.dropdownItem}
                            >
                                Create Account
                            </button>
                        ) : null}

                        <Link to="/about" style={styles.dropdownItem} onClick={() => setDropdownOpen(false)}>
                            About
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    editButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    saveButton: {
        padding: '10px 20px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    dropdownContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1,
    },
    dropdownButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    dropdownContent: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '40px',
        right: '0',
        backgroundColor: '#f1f1f1',
        boxShadow: '0px 8px 16px rgba(0,0,0,0.1)',
        zIndex: 2,
    },
    dropdownItem: {
        padding: '12px 16px',
        textAlign: 'left',
        backgroundColor: '#ffffff',
        border: 'none',
        width: '100%',
        textDecoration: 'none',
        cursor: 'pointer',
        color: '#000',
    },
};

export default Bio;
