import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUserAttributes, updateUserAttribute } from 'aws-amplify/auth'; // Import necessary functions

const Dashboard = ({ signOut }) => {
  const [preferredUsername, setPreferredUsername] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false); // For controlling dropdown visibility
  const navigate = useNavigate();

  const handleUpdateUserAttribute = useCallback(async (attributeKey, value) => {
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey,
          value
        }
      });
      handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
      console.error("Error updating user attribute:", error);
    }
  }, []);

  const handleUpdateUserAttributeNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.updateAttributeStep) {
      case 'CONFIRM_ATTRIBUTE_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(`Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`);
        break;
      case 'DONE':
        console.log(`Attribute was successfully updated.`);
        break;
      default:
        console.log(`Unexpected step: ${nextStep.updateAttributeStep}`);
        break;
    }
  };

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        setPreferredUsername(userAttributes['preferred_username']);
        setUserRole(userAttributes['custom:userRole']);

        if (!userAttributes['custom:userRole']) {
          await handleUpdateUserAttribute('custom:userRole', 'Driver');
          setUserRole('Driver');
        }
      } catch (err) {
        console.error("Error fetching user attributes: ", err);
        setPreferredUsername("Could not retrieve username.");
        setUserRole("Could not retrieve user role.");
      } finally {
        setLoading(false);
      }
    };

    getUserAttributes();
  }, [handleUpdateUserAttribute]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.welcomeText}>
        {loading ? "Loading..." : `Welcome: ${preferredUsername}!`}
      </h1>
      <h2 style={styles.roleText}>
        {loading ? "" : `Your role: ${userRole || "Not assigned"}`}
      </h2>
      <p style={styles.defaultText}>This is the default dashboard. More updates to come!</p>

      {/* Dropdown Menu */}
      <div style={styles.dropdownContainer}>
        <button onClick={toggleDropdown} style={styles.dropdownButton}>
          Menu
        </button>

        {dropdownOpen && (
          <div style={styles.dropdownContent}>
            {/* Show "Create Account" only for Admin and Sponsor */}
            {userRole === 'Admin' || userRole === 'Sponsor' ? (
              <button
                onClick={() => {
                  navigate('/create-account');
                  setDropdownOpen(false); // Close dropdown after navigating
                }}
                style={styles.dropdownItem}
              >
                Create Account
              </button>
            ) : null}
            <Link to="/about" style={styles.dropdownItem} onClick={() => setDropdownOpen(false)}>
              About
            </Link>
            <Link to="/bio" style={styles.dropdownItem} onClick={() => setDropdownOpen(false)}>
              Bio
            </Link> {/* Add link to Bio page */}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    position: 'relative',
  },
  welcomeText: {
    textAlign: 'center',
    marginTop: '20px',
  },
  roleText: {
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '18px',
    color: '#333',
  },
  defaultText: {
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '16px',
  },
  dropdownContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
  },
  dropdownButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  dropdownContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '40px',
    right: '0',
    backgroundColor: '#f1f1f1',
    boxShadow: '0px 8px 16px rgba(0,0,0,0.1)',
    zIndex: 2,
  },
  dropdownItem: {
    padding: '12px 16px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    border: 'none',
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#000',
  },
  dropdownItemHover: {
    backgroundColor: '#007bff',
    color: 'white',
  }
};

export default Dashboard;
