import React, { useState, useEffect } from 'react';
import { signUp, fetchUserAttributes } from 'aws-amplify/auth'; // Use the new signup and fetchUserAttributes method
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate for navigation

const CreateAccount = ({ signOut }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [preferredUsername, setPreferredUsername] = useState(''); // For preferred username
  const [role, setRole] = useState('Sponsor'); // Default role is Sponsor
  const [userRole, setUserRole] = useState(null); // To store the current user's role (Admin/Sponsor)
  const [loading, setLoading] = useState(true); // To manage loading state
  const [dropdownOpen, setDropdownOpen] = useState(false); // For controlling dropdown visibility

  const navigate = useNavigate(); // Initialize the navigation hook

  // Fetch the current user's role when the component mounts
  useEffect(() => {
    const fetchCurrentUserRole = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const currentRole = attributes['custom:userRole'];
        setUserRole(currentRole); // Set the user's role (Admin/Sponsor)
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching the role
      }
    };

    fetchCurrentUserRole();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signUp({
        username: email, // Use email as the Cognito username
        password,
        options: {
          userAttributes: {
            'email': email,
            'preferred_username': preferredUsername, // Set preferred username
            'custom:userRole': role, // Set the custom user role based on selection
          },
        },
      });
      alert('Account successfully created!');
    } catch (error) {
      console.error('Error creating account:', error);
      alert('Failed to create account.');
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  if (loading) {
    return <p>Loading...</p>; // Show loading while fetching user data
  }

  return (
    <div>
      {/* Dropdown Menu */}
      <div style={styles.dropdownContainer}>
        <button onClick={toggleDropdown} style={styles.dropdownButton}>
          Menu
        </button>

        {dropdownOpen && (
          <div style={styles.dropdownContent}>
            <button
              onClick={() => {
                navigate('/dashboard');
                setDropdownOpen(false); // Close dropdown after navigating
              }}
              style={styles.dropdownItem}
            >
              Dashboard
            </button>
            <Link to="/about" style={styles.dropdownItem} onClick={() => setDropdownOpen(false)}>
              About
            </Link>
            <Link to="/bio" style={styles.dropdownItem} onClick={() => setDropdownOpen(false)}>
              Bio
            </Link> {/* Add link to Bio page */}
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} style={styles.form}>
        <h2>Create Account</h2>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={styles.input}
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={styles.input}
        />
        <label>Preferred Username</label>
        <input
          type="text"
          value={preferredUsername}
          onChange={(e) => setPreferredUsername(e.target.value)}
          required
          style={styles.input}
        />
        <label>Role</label>
        <select
          value={role}
          onChange={(e) => setRole(e.target.value)}
          style={styles.input}
          disabled={userRole === 'Sponsor'} // Disable dropdown for Sponsors
        >
          {/* Admins can create either Admin or Sponsor accounts, but Sponsors can only create Sponsor accounts */}
          <option value="Sponsor">Sponsor</option>
          {userRole === 'Admin' && <option value="Admin">Admin</option>}
        </select>
        <button type="submit" style={styles.button}>Create Account</button>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    margin: '20px',
  },
  input: {
    margin: '10px 0',
    padding: '10px',
    width: '100%',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  dropdownContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
  },
  dropdownButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  dropdownContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '40px',
    right: '0',
    backgroundColor: '#f1f1f1',
    boxShadow: '0px 8px 16px rgba(0,0,0,0.1)',
    zIndex: 2,
  },
  dropdownItem: {
    padding: '12px 16px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    border: 'none',
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#000',
  },
  dropdownItemHover: {
    backgroundColor: '#007bff',
    color: 'white',
  }
};

export default CreateAccount;
