// App.js
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import outputs from './aws-exports';
import { components } from './components';
import { formFields } from './formFields';
import Dashboard from './Dashboard';
import About from './About';
import CreateAccount from './CreateAccount'; // Import CreateAccount component
import Bio from './Bio'; // Import your Bio component
import { useEffect } from 'react';

Amplify.configure(outputs);

export default function App() {
  return (
    <Router>
      <Authenticator 
        formFields={formFields} 
        components={components} 
        hideSignOut={true} // Hide the default sign-out button
      >
        {({ signOut }) => (
          <>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} /> {/* Redirect from root to dashboard */}
              <Route path="/dashboard" element={<Dashboard signOut={signOut} />} />
              <Route path="/about" element={<About />} />
              <Route path="/create-account" element={<CreateAccount />} /> {/* Add the CreateAccount route */}
              <Route path="/bio" element={<Bio />} /> {/* Add the Bio route */}
            </Routes>
            {/* Always visible sign-out button */}
            <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
              <button onClick={signOut} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                Sign Out
              </button>
            </div>
          </>
        )}
      </Authenticator>
    </Router>
  );
}
