// formFields.js
export const formFields = {
  signIn: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email',
      order: 1,
    },
  },
  signUp: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email',
      order: 1,
    },
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      placeholder: 'Please confirm your Password',
      order: 3,
    },
    preferred_username: {  // Add this new field
      label: 'Preferred Username', // Provide a label for the field
      placeholder: 'Enter your preferred username', // Placeholder for the input
      order: 4, // Order to display the field
      isRequired: true, // Set this to true if the field is mandatory
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTotp: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};
